import React, { useContext, useEffect, useState } from 'react';
import { User } from '../../Classes/User';
import { UserContext } from '../../Context/UserContext';
import { API_Login } from '../../Services/ApiRoutes';
import { Fetch } from '../../Services/Fetch';
import * as styles from './Login.module.scss';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../Loading/Loading';
import Input from '../Input/input';
import { Encrypt, EncryptData } from '../../Services/Crypto';
import { Link } from 'gatsby';
import { Button } from '../../Views/Portal/Courses/Components/Button/Button';

const LoginForm: React.FC = () => {
  const { setUser } = useContext(UserContext);
  const [userForm, setUserForm] = useState<User>({
    Email: "",
    Password: ""
  } as User);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setMessage("");
  }, [userForm]);

  const Submit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (userForm.Email && userForm.Password)
      await Fetch(API_Login, { ...userForm, Password: Encrypt(userForm.Password) }).then(u => {
        if (u.Message) {
          setMessage(u.Message);
        } else
          if (u.User && u.User.ID) {
            localStorage.setItem("User", EncryptData(u.Token))
            setUser(u.User);
            setUserForm({} as User);
          } else {
            setMessage("The Email or Password provided was incorrect");
            setLoading(false);
          }
      }).catch((ex) => {
        setMessage(ex.message);
        setLoading(false);
      });
    else {
      setMessage("Please fill in all required fields");
      setLoading(false);
    }
  }

  return (<form className={styles.Form} onSubmit={(e) => Submit(e)}>
    <div className={`${styles.FormInner}`}>
      <h1>Login</h1>
      <p>Login in here to see your Documents, Subscriptions and Manage your Employees</p>

      {loading ? <Loading /> : <>
        <Input Required Placeholder="" Type="text" Value={userForm.Email} Label="Email" Icon={faEnvelope} OnChange={(v) => setUserForm({ ...userForm, Email: v })} />
        <Input Required Placeholder="" Type="password" Value={userForm.Password} Label="Password" Icon={faLock} OnChange={(v) => setUserForm({ ...userForm, Password: v })} />

        <p>
          <Link to={`/Recover-Password`}>Forgot Your Password?</Link>
          <Link to={`/Register`}>Need An Account?</Link>
        </p>

        {message ? <div className={styles.Message}>
          {message}
        </div> : <></>}

        <Button Disabled={!userForm.Password || !userForm.Email} Type="submit" Color="Theme">Login</Button>
      </>}
    </div>
  </form>);
};

export default LoginForm;
