import React from 'react';
import { Button as Props } from './Classes/Button';
import * as styles from './Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

export const Button: React.FC<Props> = ({ Type, Color, OnClick, children, isLoading, Disabled, Icon }) => {
    return (
        <button type={Type ? Type : "button"} disabled={isLoading || Disabled} className={` ${styles.Button} ${(Color ? styles[Color] : styles.Theme)}`} onClick={(e) => OnClick ? OnClick(e) : {}}>
            {isLoading || Icon ? <i className={isLoading ? styles.Loading : ""}>
                <FontAwesomeIcon icon={isLoading ? faSpinner : Icon ? Icon : faCircle} spin={isLoading} />
            </i> : <></>}
            {(!isLoading) ? children : <></>}
            {/* <IonRippleEffect></IonRippleEffect> */}
        </button>
    );
}