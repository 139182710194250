import React from 'react';
import Layout from '../../components/Layout/layout';
import TeamPerformanceArticle from '../../Views/Website/Articles/Team-Performance/team-performance';

const EducationalGuideArticlePage: React.FC = (props: any) => {
  return (
    <Layout>
      <TeamPerformanceArticle location={props.location.href} />
    </Layout>
  );
};

export default EducationalGuideArticlePage;
