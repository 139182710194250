import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../components/Login/Login';
import * as styles from '../articles.module.scss';
import ArticleImg from '../../../../assets/IMG/freebies/team-performance/1.png';
import articlePDF from "../../../../assets/pdfs/team performance.pdf";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_BaseURL } from '../../../../Services/ApiRoutes';
import { Link } from 'gatsby';

interface Props {
  location: string;
}

const TeamPerformanceArticle: React.FC<Props> = ({ location }) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const sixthRef = useRef(null);
  const seventhRef = useRef(null);
  const eighthRef = useRef(null);
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Articles/team-performance` : "";
  const scroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Articles :: THE ULTIMATE GUIDE TO MANAGING YOUR TEAM’S PERFORMANCE</title><meta charSet='utf-8' />
        <meta property="og:description" content="Covid has changed the way that people
            all over the world work. The sudden
            move to home working, for many of us,
            worked out well. We found that after
            an initial period of adjustment, there
            were some benefits to be had for all." />
        <meta property="og:image" content={ArticleImg} />
        <meta property="og:title" content="PrimeHR :: THE ULTIMATE GUIDE TO MANAGING YOUR TEAM’S PERFORMANCE" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content="https://primehr.co.uk/Articles/team-performance" />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Articles", Text: "Articles" },
        { To: "", Text: "Team Performance" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>THE ULTIMATE GUIDE TO MANAGING YOUR TEAM’S PERFORMANCE</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            {/* <span>Written by <Link to="https://www.linkedin.com/in/nikki-hufton-bsc-hons-mcipd-fcmi-mnlp-a7528854/">Nikki Hufton</Link></span> */}
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={ArticleImg} />
          </div>

          <h2>WHETHER THEY’RE IN THE OFFICE OR NOT</h2>

          <ul>
            <li><a onClick={() => scroll(firstRef)}>The Hybrid Team</a></li>
            <li><a onClick={() => scroll(secondRef)}>Rethinking How You Manage Performance</a></li>
            <li><a onClick={() => scroll(thirdRef)}>New Starters</a></li>
            <li><a onClick={() => scroll(fourthRef)}>Utilising Technology</a></li>
            <li><a onClick={() => scroll(fifthRef)}>Handling Underperformers</a></li>
            <li><a onClick={() => scroll(sixthRef)}>Dismissal On The Grounds Of Capability</a></li>
            <li><a onClick={() => scroll(seventhRef)}>Wellness And Incentives</a></li>
            <li><a onClick={() => scroll(eighthRef)}>Need Some Expert HR Advice?</a></li>
          </ul>

          <p>
            Covid has changed the way that people
            all over the world work. The sudden
            move to home working, for many of us,
            worked out well. We found that after
            an initial period of adjustment, there
            were some benefits to be had for all.
          </p>
          <p>
            Even now, it still remains to be seen
            how permanent these changes will be.
            There’s been a lot of back and forth
            from some of the world’s biggest
            companies. Smaller businesses are
            likely to follow suit. But perhaps this
            change to home or hybrid working has
            worked well for you, and you’ve already
            made the decision to stick with it.
            Maybe you’re still making your mind
            up.
          </p>
          <p>
            Whatever the situation for your
            business, one thing is clear: the way we
            manage our teams’ performance
            needs a little adjustment. This guide
            will highlight everything you need to
            know to get the best out of your
            people, to keep them happy and
            motivated, and how you should
            manage under performers out of your
            business , wherever they’re working
            from.
          </p>
          <h3 ref={firstRef}>THE HYBRID TEAM</h3>
          <p>
            We’re hearing loads of new terms
            surrounding the way we work. The
            hybrid team is one of them. Simply put,
            it means a team that is splitting its time
            working between both an office
            location and remotely. That could
            mean you have some people working
            full time in the office and some full
            time at home, or maybe people who
            come in for a day or two a week and
            work the rest from home… Basically,
            whatever the split, if you have people
            doing both, you now have a hybrid
            team.
          </p>
          <p>
            When you have a hybrid team, some
            things will need to change. And not
            just the amount of time you spend on
            video calls.
          </p>
          <p>
            The first thing you’ll need to do is
            check your policies around hybrid
            working. Have you set out exactly what
            is expected of your hybrid workers. For
            example, will they be required to
            spend a minimum number of hours in
            the office? Can they pick and choose
            when and where they work from, when
            they feel like it? And how will you
            manage requests for hybrid working?
            Will everyone have the same
            opportunity or are there roles that
            need to have a physical presence?
          </p>
          <p>
            There’s a lot you need to consider, and
            a lot that needs to be communicated
            to your team about this. By setting out
            a clear set of principles that could be
            included in several formal policies you
            leave nobody in any doubt over what’s
            expected of them.
          </p>
          <p>
            It’s likely you’ve already seen some of
            the problems that come along with
            home working? When you don’t see all
            of your team as regularly as before, it
            can be easy to assume that everything
            is going well when, in reality, someone
            is struggling. The issue here is that it
            can become more difficult for
            problems to be communicated when
            there is less face-to-face contact.
          </p>
          <p>
            It’s really important that, as a manager
            of a hybrid team, you continue to treat
            everyone fairly and make time to meet
            with your people as you usually would.
            Ensure that everyone in your business
            knows that, just as they would in the
            office, communication channels to you
            and/or line managers are always open.
            Let everyone know the best ways to
            voice concerns or complaints, where to
            go to ask questions, and who to speak
            to should they have any problems. By
            outlining clear instructions, you make it
            easier for them to approach you. And,
            if you find that some employees are
            quieter than usual, make time to check
            in on them, just to be sure
          </p>

          <h3 ref={secondRef}>RETHINKING HOW YOU MANAGE PERFORMANCE</h3>
          <p>
            If you’re not seeing all of your team every day, it can make performance review and
            management a little trickier. We’re lucky that we now have more technology than
            ever to keep us connected and make conversations - almost - as easy as they would
            be in the office.
          </p>
          <p>
            But, when it comes to reviews and appraisals, we need to put in a little more work to
            keep things flowing. That also means looking at the metrics we’re currently using to
            measure performance. Start to think about output, quality and speed of delivery.
          </p>
          <p>
            There are three core areas you need to look at when it comes to measuring
            performance levels; your work, your team and your customers.
          </p>
          <p>
            The details can differ for each team member if they need to, but it’s important that
            each new measure is easy to record and monitor, and doesn’t place any additional
            workload on your people. Remember, a collaborative goal is just as important as an
            individual’s goals - especially in cases where you have a hybrid team, as this can be
            an indicator of how well your new flexible working policy is going.
          </p>
          <p>
            As a side note, make sure that all of your managers know exactly what measures of
            success you’re looking for, and what success actually means for you. This could be
            anything from attracting new clients, to ensuring that everyone is taking regular
            breaks and clocking off when they should.
          </p>
          <p>
            Contrary to what we might believe, rather than working from home leading to
            people slacking off, it’s actually driven a rise in presenteeism. That is, people working
            when they’re ill, working more than their usual hours, and failing to switch off when
            they need to or should. Keep an eye on exactly what hours your people are working
            and approach the subject with anyone you see struggling to fit everything into their
            day, and make sure it is still working both for them and for you as a business.
          </p>
          <p>
            Regular appraisals and 121s are essential for keeping a happy, motivated and goaloriented team. Set out exactly when your people can expect to have their 121s and
            with whom they will have them. We would recommend that 121s happen once a
            month or at least once every other month to keep everyone accountable and to
            keep lines of communication open. But also make sure that you have regular
            operational meetings and team talks to make sure the work is on track and there
            are opportunities for colleagues to help one another and work collaboratively.
          </p>
          <p>
            Depending on how you want to appraise staff will depend on how quickly things
            move in your business. Again, formally arrange these meetings so that everyone
            knows what to expect and when. Appraisal systems can vary and it can feel time
            consuming in a small business so you may want to adopt an approach like
            Instructional Coaching setting quarterly goals that can be broken down into monthly
            or even weekly outputs with clear measures of success. You may find that new or
            less confident staff need more check-ins than your more established or competent
            colleagues.
          </p>
          <p>
            Most importantly what ever approach you take train all of your line managers in
            holding effective review meetings. This will not only make it more realistic that
            reviews will happen when they’re supposed to, but it will also mean that reviews are
            doing what they’re supposed to do - encouraging your people to set and achieve
            new goals and targets. It is important to ensure that everyone is happy and has no
            issues with their role or their colleagues and that it generally opens up an effective
            time to communicate in private.
          </p>
          <p>
            Don’t forget that every review should have an element of goal setting. This is to help
            your people stay focused on what they want to achieve, to help them grow their
            skills and knowledge, and to allow them to ‘move up the ladder’ in your business, if
            that’s what they are looking to do.
          </p>
          <p>
            Every goal you set in any meeting should be a SMART objective. As a quick reminder,
            that means they should be:
          </p>
          <p>
            Specific - What exactly is the goal and why is this goal being set?
            Measurable - How will you know when the goal has been hit?
            Achievable - Is it realistic based on everything else this person has to do?
            Relevant - Will hitting this goal make a difference to their role or your business?
            Time-bound - When will this be achieved by?
          </p>

          <h3 ref={thirdRef}>NEW STARTERS</h3>

          <p>
            Hopefully, your business is still going from strength to strength and, at some
            point, you will be looking at taking on new people. This may be new people to
            grow the business, or to replace people who have left you.
          </p>
          <p>
            With all of the changes to the way we’re working, it’s equally important to be
            mindful of how you attract and onboard new talent.
          </p>
          <p>
            First things first, when it comes to advertising a role, it’s vital that you create a
            detailed job description before you do anything else. This allows you to
            identify exactly what you need in a new employee, the skills, knowledge and
            experience you’d like them to have, and it gives you a clear understanding of
            what they’ll be doing day-to-day. It will also help to filter out any time wasters
            from your recruitment process.
          </p>
          <p>
            When you do find the ideal candidate, it’s important that you have a
            thorough onboarding process set up ready to go on their first day. Make sure
            you have everything ready for them to hit the ground running, including a
            device to work on (in the office, at home, or both), that they have accounts
            set up in all of the software they’ll be using, an email account is ready to go,
            and that they also have anything else they’ll require to carry out their role
            effectively.
          </p>
          <p>
            Include a full training itinerary allowing them time to become familiar with
            software, to shadow any other employees, and to see how everyone else’s
            jobs fit into the bigger picture too. It’s also a good idea to build in time for a
            guided tour of your offices, whether they’ll be working in them or not. It all
            goes towards giving off a great impression of your business, and helping
            them to feel welcome and included from the get-go.
          </p>
          <p>
            Now to the not-so-nice side of bringing in new people… what if it doesn’t
            work out?
          </p>
          <p>
            This is exactly what a probationary period is for. Use it!
          </p>
          <p>
            A probationary period works to protect your business from a bad hiring
            decision, giving you time to see if your new hire is right for your company,
            and they role they’ve been hired to do.
          </p>
          <p>
            From a legal standpoint, the day a new hire starts, whether they’re subject
            to a probationary period or not, is the day that their full contractual rights
            begin. You will need to include any terms of a probationary period in their
            contract of employment, making it clear that certain terms will only apply
            once they’ve successfully passed any such period or frame of time. It’s
            also vital that you include information on whether a probationary period
            may be extended within the contract, too.
          </p>
          <p>
            Though there are no rules to the length of a probationary period, it’s
            expected that an employer will be reasonable. Typically, a probationary
            period is no longer than six months (though you may wish to include a
            term that states it may be extended if necessary) for a new hire, and three
            months for an employee changing roles within the business.
          </p>
          <p>
            During this period, you need to make clear what your expectations of your
            new employee are, the values and behaviours that must be displayed, and
            any standards that must be adhered to. A series of review meetings
            should also be scheduled during this period, where both parties can raise
            any concerns they may have and review performance levels.
          </p>
          <p>
            If things don’t work out for either party, it is an easier process to terminate
            employment during a probationary period.
          </p>
          <p>
            Once an employee has been continually employed with your business for
            two years, they are entitled to claim unfair dismissal. If they’ve worked for
            less than this time, an employment tribunal will add their notice period to
            their length of service (unless they were let go for gross misconduct). Your
            employee is also within their rights to request a written document
            detailing the reason for dismissal. We would suggest that you always seek
            professional HR advice before you start any termination process.
          </p>
          <p>
            Another thing to be mindful of is that any employee, regardless of how
            long they’ve worked for you, can claim for unfair dismissal if they have a
            protected characteristic. As a reminder, these are age, disability, gender
            reassignment, marriage and civil partnership, pregnancy or maternity,
            race, religion or belief, sex, or sexual orientation.
          </p>

          <h3 ref={fourthRef}>UTILISING TECHNOLOGY</h3>

          <p>
            As I’ve already mentioned, one thing that really
            flourished thanks to the pandemic is
            technology. We’ve been furnished with all the
            tools we could possibly need to work remotely,
            without disruption to our communication and
            collaboration. We couldn’t have managed this
            well even 10 years ago.
          </p>
          <p>
            So, while you’re embracing video calling, file
            sharing and virtual offices, it’s also time you
            looked to other forms of technology to make
            your working life easier.
          </p>
          <p>
            There are countless HR software packages
            available to help you keep track of all of the
            aspects that come with employing people.
            Things like holiday requests, sickness and
            absence, meeting and appraisal notes, and
            personal information can be stored and
            managed within one app.
          </p>
          <p>
            But there are other apps that you can use to
            help manage your peoples’ performance, too.
            Project management software can help you to
            see exactly how well projects are progressing,
            how communication is handled and how much
            each member of the team is contributing.
          </p>
          <p>
            There are also time management apps that
            allow you to track the times spent on tasks,
            showing you whether your people are using
            their time effectively, and reporting software
            that can send you automatic reports daily,
            weekly, or monthly.
          </p>
          <p>
            When it comes to performance reviews, this
            kind of data can be very valuable.
          </p>

          <h3 ref={fifthRef}>HANDLING UNDERPERFORMERS</h3>

          <p>
            There will undoubtedly come a time when you have to deal with underperformance in
            your business. Whether that is a whole team’s performance or an individuals will dictate
            how you deal with such an issue.
          </p>
          <p>
            If a team is underperforming, it may be a sign that your managers need to up their
            game.
          </p>
          <p>
            However, if an individual is underperforming, you need to tackle the issue immediately
            and follow a specific process to fix the problem.
          </p>
          <p>
            If you’ve been carrying out your reviews and appraising as usual and they haven’t had an
            impact on your underperformer, it may be time to signal that you are moving towards a
            more formal improvement plan. This is a process that should manage your employee’s
            performance in greater depth than regular reviews do. You will need to set a series of
            performance improvement goals and set out steps that your employee must complete
            in order to meet those goals.
          </p>
          <p>
            You may decide to use the review template in place and add more regular check-ins.
            However if you haven't documented anything then a typical performance improvement
            plan would involve you meeting with the employee and starting a conversation to
            highlight the performance issue(s) and to set out how they should improve. You should
            discuss what they think they should be doing better, and you can let them know your
            expectations. This should all be documented formally, with a copy being kept by both
            parties. Include these sections on the document:
          </p>
          <p>
            <p>• Employee’s name, position and the date</p>
            <p>• Manager’s name</p>
            <p>• Performance issue or concern</p>
            <p>• Expected improvement actions</p>
            <p>• Support and dependencies</p>
            <p>• Due date</p>
            <p>• Manager’s comments</p>
            <p>• Employee’s comments</p>
            <p>• Sign and date (both parties)</p>
          </p>
          <p>
            Then you will need to hold progress meetings to see what improvements are being
            made and whether the plan is being adhered to. You should also include on the plan:
          </p>
          <p>
            <p>• Progress summary</p>
            <p>• Outcome</p>
            <p>• Comments</p>
            <p>• Next steps</p>
          </p>
          <p>
            Hopefully, after completing this process, you will see a marked improvement in your
            employee’s performance. However, in some cases you may not. Then it may be time to
            take further action.
          </p>

          <h3 ref={sixthRef}>DISMISSAL ON THE GROUNDS OF CAPABILITY</h3>

          <p>
            Sometimes, unfortunately, it’s necessary for you
            to dismiss an employee on the grounds of
            capability.
          </p>
          <p>
            You may find that someone doesn’t have the
            right aptitude or skills to complete their role
            effectively. Hopefully, you’d find this out while a
            new starter was in their probationary period,
            making it easier for you to remove them from
            the business. However, in some cases, perhaps
            where a role has changed or developed over
            time, this may happen with a long-term
            employee.
          </p>
          <p>
            It may also be the case that the employee has
            become unwilling or reluctant to do their job -
            and this can be for any number of reasons.
          </p>
          <p>
            In any case, it’s important that you still follow
            your disciplinary or capability policy and
            dismissal procedures to the letter, to
            demonstrate that you’ve acted reasonably and
            fairly, and to avoid any legal proceedings in the
            future.
          </p>

          <h3 ref={seventhRef}>WELLNESS AND INCENTIVES</h3>

          <p>
            A lot of people perceive managing your team’s performance as, well, a bit horrible.
            But it doesn’t have to all be doom and gloom. Not everyone will have issues and, if
            you’re doing things the right way, it should be the case that you have a team of
            happy, motivated individuals.
          </p>
          <p>
            One thing that you should be mindful of, especially when it comes to performance,
            is the wellness of your people, and the incentives you’re giving them to perform well.
          </p>
          <p>
            You’ve probably noticed that since the pandemic began, our wellness has taken a
            huge hit. Covid burnout became an issue for even the most resilient among us, and
            it was no surprise.
          </p>
          <p>
            But, again, it’s worked out to be a good thing in a sense, because it’s forced many
            businesses to place more importance on the wellbeing of their people. And the
            result of this can be a happier, more motivated and productive workforce. Look after
            your team and they’ll look after you.
          </p>
          <p>
            Speak to your team - perhaps your 121s would be a good time to do this - and find
            out what it is that they work for. You might be surprised that for many people
            money isn’t the primary reason for working. Of course, it’s something we all need to
            live, but it might not be the thing that motivates them most.
          </p>
          <p>
            If you’re looking to give your employees perks and incentives, look at alternatives to
            money, such as gym memberships, extra time off, or even vouchers towards
            something they’ve been saving for. Ask the right questions and you’ll soon learn
            what drives them, and therefore what the best reward would be for them hitting
            targets, goals, or even just doing a great job on something.
          </p>
          <p>
            When it comes to wellness, encourage time off, taking proper lunch breaks, and
            switching off from work at the right time. If you spot signs of anyone struggling, or
            someone comes to you with a mental or physical health problem, take them
            seriously and demonstrate that yours is a business that values its employees’
            wellbeing. This kind of thing can really make or break a business when it comes to
            employee loyalty.
          </p>
          <p>
            And remember, if people are working away from the office, they’ll still need an
            element of communication to keep them happy and healthy. Check in on everyone
            from time to time, and make it clear that, even though people may be working at
            home, they are still able to speak to you at any time if they need to.
          </p>

          <h3 ref={eighthRef}>NEED SOME EXPERT HR ADVICE?</h3>

          <p>
            If you’re dealing with employee performance or not
            sure what to do, we’d love to help you. Simply give us
            a call or drop us an email to arrange a conversation.
          </p>
          <p>
            Simply contact our team on <b>01158 372 772</b> or sign up
            for our legally compliant <b>Capability Procedure and
              Guidance notes</b>.
          </p>
          <p>
            We're here to help your small business grow! And
            here when you need us most.
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.Download}>
            <a download href={articlePDF}>
              <i>
                <FontAwesomeIcon icon={faCloudDownloadAlt} />
              </i>
              Download Article
            </a>
          </div>

          <div className={styles.SectionSideInfo}>
            <h4>Found This Article Useful?</h4>
            <p>If you found this article or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

            <div className={styles.Download}>
              <Link to="/Register">
                Create an Account
              </Link>
            </div>
          </div>

          <h4><span>Or</span></h4>

          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default TeamPerformanceArticle;