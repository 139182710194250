// extracted by mini-css-extract-plugin
export var Button = "Button-module--Button--ae26f";
export var Green = "Button-module--Green--db206";
export var GreenLink = "Button-module--GreenLink--552f3";
export var Link = "Button-module--Link--6f895";
export var Loading = "Button-module--Loading--2746a";
export var Orange = "Button-module--Orange--1f357";
export var OrangeLink = "Button-module--OrangeLink--76f82";
export var OutlineTheme = "Button-module--OutlineTheme--bed66";
export var Red = "Button-module--Red--42388";
export var RedLink = "Button-module--RedLink--7bae2";
export var Theme = "Button-module--Theme--d2434";