import React, { useEffect, useState } from 'react';
import * as styles from './Loading.module.scss';

interface Props {
  Titles?: string[];
}

export const Loading: React.FC<Props> = ({ Titles }) => {
  const [title, setTitle] = useState<number>(0);

  useEffect(() => {
    if (Titles && Titles.length > 1)
      setTimeout(() => {
        if ((title + 1) === Titles.length)
          setTitle(0)
        else
          setTitle(title + 1)
      }, 1000);
  }, [Titles]);

  return (<div className={styles.Loading}>
    <div className={styles.LoadingInner}>
    </div>
    {
      Titles ? Titles.map((t, i) => {
        return <h1 className={i === title ? styles.Show : ""}>{t}</h1>
      }) : <></>
    }
  </div>);
};

